.site-navbar {
    margin-bottom: 0px;
    z-index: 1999;
    position: absolute;
    width: 100%; }
.site-navbar .container-fluid {
    padding-left: 7rem;
    padding-right: 7rem;
}
@media (max-width: 1199.98px) {
    .site-navbar .container-fluid {
        padding-left: 15px;
        padding-right: 15px; } }
.site-navbar .site-logo {
    position: absolute; }
@media (max-width: 992px) {
    .site-navbar .site-logo {
        position: relative; } }
.site-navbar .site-logo a {
    color: #fff;
    font-size: 1.5rem;
    letter-spacing: .2rem;
    text-transform: uppercase; }
.site-navbar .site-logo a:hover {
    text-decoration: none; }
.site-navbar .site-burger-menu {
    position: absolute; }
@media (max-width: 1199.98px) {
    .site-navbar .site-burger-menu {
        position: relative; } }
.site-navbar .site-menu-toggle {
    color: #fff; }
.site-navbar .site-navigation .site-menu {
    margin-bottom: 0; }
.site-navbar .site-navigation .site-menu .active {
    color: #fff;
    display: inline-block;
    padding: 5px 20px; }
.site-navbar .site-navigation .site-menu a {
    text-decoration: none !important;
    display: inline-block;
    position: relative; }
.site-navbar .site-navigation .site-menu > li {
    display: inline-block; }
.site-navbar .site-navigation .site-menu > li > a {
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    text-decoration: none !important; }
.site-navbar .site-navigation .site-menu > li > a:hover {
    color: #fff; }
.site-navbar .site-navigation .site-menu .has-children {
    position: relative; }
.site-navbar .site-navigation .site-menu .has-children > a {
    position: relative;
    padding-right: 20px; }
.site-navbar .site-navigation .site-menu .has-children > a:before {
    position: absolute;
    content: "\e313";
    font-size: 16px;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
     }
.site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
    cursor: pointer; }
.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    margin-top: 0px;
    visibility: visible;
    opacity: 1; }
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a {
    color: black;
    position: relative; }
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a:after {
    height: 2px;
    background: #000;
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a:hover, .site-navbar .site-navigation .site-menu.site-menu-dark > li > a.active {
    color: #007bff;
    position: relative; }
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a:hover:after, .site-navbar .site-navigation .site-menu.site-menu-dark > li > a.active:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }

.site-mobile-menu {
    width: 300px;
    position: fixed;
    right: 0;
    z-index: 2000;
    padding-top: 20px;
    background: #fff;
    height: calc(100vh);
    -webkit-transform: translateX(110%);
    -ms-transform: translateX(110%);
    transform: translateX(110%);
    -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
    box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.2s all cubic-bezier(0.66, 0.2, 0.48, 0.9);
    -o-transition: 0.2s all cubic-bezier(0.66, 0.2, 0.48, 0.9);
    transition: 0.2s all cubic-bezier(0.66, 0.2, 0.48, 0.9); }
.offcanvas-menu .site-mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); }
.site-mobile-menu .site-mobile-menu-header {
    width: 100%;
    float: left;
    padding-left: 20px;
    padding-right: 20px; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
    float: right;
    margin-top: 8px; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
    font-size: 30px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 0px;
    line-height: 1;
    cursor: pointer;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
    color: #dee2e6; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
    float: left;
    margin-top: 10px;
    margin-left: 0px; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
    display: inline-block;
    text-transform: uppercase; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
    max-width: 70px; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
    text-decoration: none; }
.site-mobile-menu .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 0 20px 20px 20px;
    height: calc(100vh - 52px);
    padding-bottom: 150px; }
.site-mobile-menu .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative; }
.site-mobile-menu .site-nav-wrap a {
    padding: 10px 20px;
    display: block;
    position: relative;
    color: #212529; }
.site-mobile-menu .site-nav-wrap a:hover {
    color: #007bff;
    text-decoration: none; }
.site-mobile-menu .site-nav-wrap li {
    position: relative;
    display: block; }
.site-mobile-menu .site-nav-wrap li .active {
    color: #000; }
.site-mobile-menu .site-nav-wrap .arrow-collapse {
    position: absolute;
    right: 0px;
    top: 10px;
    z-index: 20;
    width: 36px;
    height: 36px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%; }
.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
    background: #f8f9fa; }
.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
    font-size: 12px;
    z-index: 20;
    font-family: "icomoon";
    content: "\f078";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(-180deg);
    -ms-transform: translate(-50%, -50%) rotate(-180deg);
    transform: translate(-50%, -50%) rotate(-180deg);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
.site-mobile-menu .site-nav-wrap > li {
    display: block;
    position: relative;
    float: left;
    width: 100%; }
.site-mobile-menu .site-nav-wrap > li > a {
    padding-left: 20px;
    font-size: 20px; }
.site-mobile-menu .site-nav-wrap > li > ul {
    padding: 0;
    margin: 0;
    list-style: none; }
.site-mobile-menu .site-nav-wrap > li > ul > li {
    display: block; }
.site-mobile-menu .site-nav-wrap > li > ul > li > a {
    padding-left: 40px;
    font-size: 16px; }
.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
    padding: 0;
    margin: 0; }
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
    display: block; }
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
    font-size: 16px;
    padding-left: 60px; }
.site-mobile-menu .site-nav-wrap[data-class="social"] {
    float: left;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 5em; }
.site-mobile-menu .site-nav-wrap[data-class="social"] > li {
    width: auto; }
.site-mobile-menu .site-nav-wrap[data-class="social"] > li:first-child a {
    padding-left: 15px !important; }

.sticky-wrapper {
    position: absolute;
    z-index: 100;
    width: 100%; }
.sticky-wrapper + .site-blocks-cover {
    margin-top: 96px; }
.sticky-wrapper .site-menu-toggle {
    color: #000; }
@media (max-width: 991.98px) {
    .sticky-wrapper .site-menu-toggle {
        color: #fff; } }
.sticky-wrapper .site-navbar {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
.sticky-wrapper .site-navbar .site-menu > li {
    display: inline-block; }
.sticky-wrapper .site-navbar .site-menu > li > a {
    position: relative; }
.sticky-wrapper .site-navbar .site-menu > li > a:after {
    height: 2px;
    background: #fff;
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
.sticky-wrapper .site-navbar .site-menu > li > a:hover, .sticky-wrapper .site-navbar .site-menu > li > a.active {
    color: #fff;
    position: relative; }
.sticky-wrapper .site-navbar .site-menu > li > a:hover:after, .sticky-wrapper .site-navbar .site-menu > li > a.active:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
.sticky-wrapper.is-sticky .site-menu-toggle {
    color: #000; }
.sticky-wrapper.is-sticky .site-navbar {
    -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
    background: #fff; }
.sticky-wrapper.is-sticky .site-navbar .site-logo a {
    color: #000; }
.sticky-wrapper.is-sticky .site-navbar .site-menu > li {
    display: inline-block; }
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a {
    position: relative;
    color: #000; }
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:after {
    height: 2px;
    background: #007bff;
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:hover, .sticky-wrapper.is-sticky .site-navbar .site-menu > li > a.active {
    color: #007bff;
    position: relative; }
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:hover:after, .sticky-wrapper.is-sticky .site-navbar .site-menu > li > a.active:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
.sticky-wrapper .shrink {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }

body {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.7;
    position: relative; }
body:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
body.offcanvas-menu {
    position: relative; }
body.offcanvas-menu:after {
    opacity: 1;
    visibility: visible; }

h1, h2, h3, h4 {
    color: #ffffff;
}

.site-section {
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;
    z-index: 1; }
@media (max-width: 767px){
    .site-section{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.text-black {
    color: #000 !important; }
section{
    margin-bottom: 1%;
}

.home-section {
    position: relative;
    background-color: #001f42;
}
.home-section h1 {
    font-size: 4.5rem;
    color: #fff;
    font-weight: 200;
    letter-spacing: .2rem;
    text-transform: uppercase; }
@media (max-width: 991.98px) {
    .home-section h1 {
        font-size: 2.5rem; } }
.home-section p {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.6); }
.home-section a {
    font-size: 1.2rem;
    text-transform: none; }
.home-section a, .home-section a:hover {
    color: #fff; }
.home-section .btn {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: .2rem; }
.home-section .scroll-button {
    position: absolute;
    z-index: 5;
    bottom: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #007bff;
    left: 50%;
    -webkit-transform: translate(-50%, -150%);
    -ms-transform: translate(-50%, -150%);
    transform: translate(-50%, -150%);
    -webkit-box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.3); }
.home-section .scroll-button > span {
    position: absolute;
    font-size: 2rem;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.spepcial_link {
    position: relative;
    text-decoration: none !important;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: .9rem; }
.spepcial_link:before {
    position: absolute;
    z-index: -1;
    background: #629ad9;
    content: "";
    width: 100%;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    left: 0;
    bottom: 0;
    top: 50%;
    width: 0; }
.spepcial_link:hover {
    color: #ffffff; }
.spepcial_link:hover:before {
    width: 100%; }

.img-overlap {
    width: 40% }
.img-overlap .img-1, .img-overlap .img-2 {
    padding: 4%;
    max-width: 500px; }
@media (max-width: 992px) {
    .img-overlap .img-1, .img-overlap .img-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 275%;
        max-width: 500px;
    } }
.img-overlap .img-1 {
    top: 0; }
.img-overlap .img-2 {
    bottom: -20%;
    right: -10%; }
@media (max-width: 991.98px) {
    .img-overlap .img-2 {
        bottom: 30%; } }

.img-shadow {
    border-radius: 7px;
    -webkit-box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.4); }

.section-title {
    font-size: 2rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    font-weight: 300; }

.section-title-sub {
    font-size: 1rem;
    color: #ced4da; }

.section-hero, .section-hero > .container > .row {
    height: 100vh;
    min-height: 900px; }

.section-hero.overlay, .section-hero.overlay-2 {
    position: relative; }
.section-hero.overlay:before, .section-hero.overlay-2:before {
    z-index: 1;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(125, 131, 132, 0.3); }

.section-hero.overlay-2:before {
    background: rgba(125, 131, 132, 0.95); }

.section-hero > .container {
    z-index: 2;
    position: relative; }

.video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1; }
.video-container video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.btn:after, .btn:before {
    display: none; }

.btn:hover, .btn:focus, .btn:active {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none;


}


.btn.btn-primary:hover {
    background: #3395ff;
    border-color: #3395ff; }

.btn.btn-outline-white {
    background: transparent;
    border-width: 2px;
    border-color: #fff; }
.btn.btn-outline-white:hover {
    background: #fff;
    color: #000; }

.btn-md {
    padding: 12px 20px; }

.slanted:after, .slanted-gray:after {
    content: "";
    background: transparent;
    height: 200px;
    -webkit-transform: skewY(-5deg);
    -ms-transform: skewY(-5deg);
    transform: skewY(-5deg);
    position: absolute;
    left: 0;
    bottom: -100px;
    right: 0;
    z-index: 1; }

.slanted-gray:after {
    background: #f8f9fa !important; }
#counterPart .caption{
    font-size: 1.5rem;
}
.homeIcon svg{
    font-size: 3.5rem;
}
.block__19738 {
    text-align: center; }
.block__19738 [class^="icon-"], .block__19738 [class^="flaticon-"] {
    font-size: 4rem; }
.block__19738 .number {
    font-size: 3.5rem;
    font-weight: 300;
    color: #007bff; }
.block__19738 .caption {
    font-size: 1rem;
    display: block;
    color: #adb5bd; }

.block__62849 .block__16443 {
    background: #fff;
    padding: 30px;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    top: 0;
    position: relative;
    text-decoration: none; }
.block__62849 .block__16443 .custom-icon {
    display: block;
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #007bff;
    background: #007bff;
    margin-bottom: 1.5rem;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
.block__62849 .block__16443 .custom-icon:before {
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    width: 90px;
    height: 90px;
    border: 1px solid rgba(0, 123, 255, 0.2); }
.block__62849 .block__16443 [class^="icon-"], .block__62849 .block__16443 [class^="flaticon-"] {
    font-size: 1.6rem;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: 0.3s all cubic-bezier(0.66, 0.2, 0.48, 0.9);
    -o-transition: 0.3s all cubic-bezier(0.66, 0.2, 0.48, 0.9);
    transition: 0.3s all cubic-bezier(0.66, 0.2, 0.48, 0.9); }
.block__62849 .block__16443 h3 {
    font-size: 1.2rem;
    margin-bottom: 1.2rem; }
.block__62849 .block__16443 p {
    color: #212529; }
.block__62849 .block__16443:hover {
    top: -20px;
    -webkit-box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2); }
@media (max-width: 991.98px) {
    .block__62849 .block__16443:hover {
        top: 0; } }
.block__62849 .block__16443:hover .custom-icon {
    background: transparent; }
.block__62849 .block__16443:hover .custom-icon [class^="icon-"], .block__62849 .block__16443:hover .custom-icon [class^="flaticon-"] {
    color: #007bff; }

.block__62272 .section-title {
    font-size: 2rem; }

.block__87154 {
    position: relative;
    background: #f8f9fa;
    padding: 30px;
    margin-bottom: 2rem;
    border-radius: 7px; }
.block__87154 *:last-child {
    margin-bottom: 0; }
.block__87154 .quote {
    position: absolute;
    -webkit-transform: translateY(-150%);
    -ms-transform: translateY(-150%);
    transform: translateY(-150%); }
.block__87154 blockquote {
    margin-bottom: 1.6rem; }
.block__87154 blockquote p {
    font-size: 1.2rem; }
.block__87154 .block__91147 figure {
    margin-bottom: 0; }
.block__87154 .block__91147 img {
    max-width: 40px;
    border-radius: 50%;
    margin: 0px;
    padding: 0; }
.block__87154 .block__91147 h3 {
    margin: 0px;
    padding: 0;
    font-size: 1rem; }
.block__87154 .position {
    font-size: .8rem;
    color: #adb5bd; }
.block__87154 .position.position-2 {
    color: rgba(255, 255, 255, 0.6); }

.block__96788 {
    position: relative;
    display: block; }
.block__96788 .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
.block__96788 .play-icon span {
    font-size: .8rem;
    color: #000;
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.block__86547 {
    position: relative;
    background: #f8f9fa;
    overflow: hidden;
    border-radius: 4px;
    -webkit-transition: 0.2s all cubic-bezier(0.66, 0.2, 0.48, 0.9);
    -o-transition: 0.2s all cubic-bezier(0.66, 0.2, 0.48, 0.9);
    transition: 0.2s all cubic-bezier(0.66, 0.2, 0.48, 0.9);
    top: 0; }
.block__86547 figure {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 180px;
    flex: 0 0 180px;
    margin-bottom: 0;
    background-size: cover;
    background-position: center center; }
@media (max-width: 1199.98px) {
    .block__86547 figure {
        height: 200px; } }
.block__86547 > div {
    padding: 40px; }
.block__86547 > div h3 {
    font-size: 1.4rem; }
.block__86547 > div h3 a {
    color: #000; }
.block__86547 > div h3 a:hover {
    text-decoration: none; }
.block__86547 > div .block__27192 > a {
    font-size: .9rem;
    display: inline-block;
    margin: 10px 20px;
    margin-left: 0;
    color: #495057; }
.block__86547 > div .block__27192 > a span {
    color: #adb5bd; }
.block__86547 > div .block__27192 > a:hover {
    text-decoration: none; }
.block__86547:hover {
    top: -10px;
    background: #fff;
    -webkit-box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.2); }

.block__45439 {
    padding: 7em 0; }

.block__69944 .text-muted {
    color: #ced4da !important; }

.block__76208 .accordion-item .heading {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0; }
.block__76208 .accordion-item .heading > a {
    padding-left: 35px;
    position: relative;
    color: #000; }
.block__76208 .accordion-item .heading > a:before {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 18px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 18px;
    top: .1em;
    left: 0;
    border-radius: 50%; }
.block__76208 .accordion-item .heading > a[aria-expanded="true"]:before {
    font-family: 'icomoon';
    position: absolute;
    content: "\e316";
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    background: #007bff;
    color: #fff;
    border: 1px solid #007bff; }
.block__76208 .accordion-item .heading > a[aria-expanded="false"]:before {
    content: "\e313";
    color: #ccc;
    font-family: 'icomoon';
    position: absolute;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }

.block__76208 .accordion-item .body-text {
    font-size: 16px;
    padding: 5px 0;
    padding-left: 30px; }

.item {
    border: none; }
.item a {
    display: block;
    overflow: hidden;
    position: relative; }
.item a img {
    position: relative;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }
.item .item-wrap {
    display: block;
    position: relative; }
.item .item-wrap:after {
    z-index: 2;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }
.item .item-wrap > span {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    color: #fff;
    font-size: 1.7rem;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
.item .item-wrap:hover:after {
    opacity: 1;
    visibility: visible; }
.item .item-wrap:hover span {
    margin-top: 0px;
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); }
.item:hover a img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }

.site-menu-toggle:hover {
    text-decoration: none; }

.site-footer {
    padding: 7rem 0;
    position: relative;
    background: #2a2a2a;
    margin-top: 10rem; }
@media (max-width: 991.98px) {
    .site-footer {
        padding: 2rem 0; } }
.site-footer.slanted-footer:before {
    content: "";
    background: #2a2a2a;
    height: 200px;
    -webkit-transform: skewY(-5deg);
    -ms-transform: skewY(-5deg);
    transform: skewY(-5deg);
    position: absolute;
    left: 0;
    top: -100px;
    right: 0;
    z-index: -1; }
.site-footer h3 {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #fff; }
.site-footer a {
    color: #ffffff; }
.site-footer a:hover {
    color: #fff; }
.site-footer ul li {
    margin-bottom: .5rem; }
.site-footer ul li a {
    color: #ffffff; }
.site-footer ul li a:hover {
    color: #fff; }
.site-footer .scroll-top {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 50px;
    height: 50px;
    border: 1px solid white;
    border-radius: 50%;
    background: #2a2a2a;
    left: 50%;
    -webkit-transform: translate(-50%, -250%);
    -ms-transform: translate(-50%, -250%);
    transform: translate(-50%, -250%);
    -webkit-box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.3); }
.site-footer .scroll-top > span {
    position: absolute;
    font-size: 2rem;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff !important; }

.copyright {
    color: rgba(255, 255, 255, 0.5); }

.footer-social a, .social a {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    background: #007bff;
    border-radius: 50%;
    text-decoration: none !important; }
.footer-social a > span, .social a > span {
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
.footer-social a:hover, .social a:hover {
    background: #fff; }
.footer-social a:hover span, .social a:hover span {
    color: #007bff; }

.social a:hover {
    background: #343a40; }
.social a:hover span {
    color: #fff; }

.filters .btn {
    border-color: transparent;
    font-size: .8rem;
    margin: 5px;
    background: rgba(52, 58, 64, 0.05);
    color: #343a40;
    text-transform: uppercase; }
.filters .btn:hover {
    border-color: transparent;
    background: #007bff;
    color: #fff; }
.filters .btn.active {
    background: #007bff !important;
    color: #fff;
    border-color: #007bff !important;
    -webkit-box-shadow: 0 4px 15px -3px rgba(0, 123, 255, 0.5) !important;
    box-shadow: 0 4px 15px -3px rgba(0, 123, 255, 0.5) !important; }

/* Isotope Transitions
------------------------------- */
.isotope,
.isotope .item {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -ms-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s; }

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -ms-transition-property: height, width;
    -o-transition-property: height, width;
    transition-property: height, width; }

.isotope .item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    -o-transition-property: top, left, opacity;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform; }

/* responsive media queries */
/* Mouse scroll effect */
.mouse {
    position: absolute;
    bottom: 40px;
    z-index: 5;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block; }
.mouse .mouse-icon {
    display: block;
    width: 25px;
    height: 45px;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    text-align: center;
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }
.mouse .mouse-wheel {
    height: 4px;
    margin: 2px auto 0;
    display: block;
    width: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-animation: 1.6s ease infinite wheel-up-down;
    -moz-animation: 1.6s ease infinite wheel-up-down;
    animation: 1.6s ease infinite wheel-up-down; }
.mouse:hover .mouse-icon {
    border-color: #fff; }

@-webkit-keyframes wheel-up-down {
    0% {
        margin-top: 2px;
        opacity: 0; }
    30% {
        opacity: 1; }
    100% {
        margin-top: 20px;
        opacity: 0; } }

@-moz-keyframes wheel-up-down {
    0% {
        margin-top: 2px;
        opacity: 0; }
    30% {
        opacity: 1; }
    100% {
        margin-top: 20px;
        opacity: 0; } }

@keyframes wheel-up-down {
    0% {
        margin-top: 2px;
        opacity: 0; }
    30% {
        opacity: 1; }
    100% {
        margin-top: 20px;
        opacity: 0; } }

.ul-check {
    margin-bottom: 50px; }
.ul-check li {
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    line-height: 1.5; }
.ul-check li:before {
    left: 0;
    font-size: 20px;
    top: -.3rem;
    font-family: "icomoon";
    content: "\e5ca";
    position: absolute; }
.ul-check.white li:before {
    color: #fff; }
.ul-check.success li:before {
    color: #28a745; }
.ul-check.primary li:before {
    color: #007bff; }

.block__18514 .block__47528 li {
    margin-bottom: 10px; }
.block__18514 .block__47528 li span {
    font-weight: 700;
    color: #000; }
.block__18514 .block__47528 li a {
    display: block; }

.sidebar-box {
    margin-bottom: 30px;
    padding: 25px;
    font-size: 15px;
    width: 100%;
    float: left;
    background: #fff; }
.sidebar-box *:last-child {
    margin-bottom: 0; }
.sidebar-box h3 {
    font-size: 18px;
    margin-bottom: 15px; }

.categories li, .sidelink li {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dotted #dee2e6;
    list-style: none; }
.categories li:last-child, .sidelink li:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0; }
.categories li a, .sidelink li a {
    display: block; }
.categories li a span, .sidelink li a span {
    position: absolute;
    right: 0;
    top: 0;
    color: #ccc; }
.categories li.active a, .sidelink li.active a {
    color: #000;
    font-style: italic; }

.comment-form-wrap {
    clear: both; }

.comment-list {
    padding: 0;
    margin: 0; }
.comment-list .children {
    padding: 50px 0 0 40px;
    margin: 0;
    float: left;
    width: 100%; }
.comment-list li {
    padding: 0;
    margin: 0 0 30px 0;
    float: left;
    width: 100%;
    clear: both;
    list-style: none; }
.comment-list li .vcard {
    width: 80px;
    float: left; }
.comment-list li .vcard img {
    width: 50px;
    border-radius: 50%; }
.comment-list li .comment-body {
    float: right;
    width: calc(100% - 80px); }
.comment-list li .comment-body h3 {
    font-size: 20px; }
.comment-list li .comment-body .meta {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .1em;
    color: #ccc; }
.comment-list li .comment-body .reply {
    padding: 5px 10px;
    background: #e6e6e6;
    color: #000;
    text-transform: uppercase;
    font-size: 14px; }
.comment-list li .comment-body .reply:hover {
    color: #000;
    background: #e3e3e3; }

.search-form {
    background: #f7f7f7; }
.search-form .form-group {
    position: relative; }
.search-form .form-group input {
    padding-right: 50px; }
.search-form .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

.post-meta {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: .2em; }
.post-meta a {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }

/*PRELOADING------------ */
#overlayer {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 7100;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.loader {
    z-index: 7700;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
